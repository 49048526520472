import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faPhone,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";
import { API_URL } from "../config";
import { useNavigate } from "react-router-dom";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Swal from "sweetalert2";
import InputMask from "react-input-mask";
import StateMunicipalityDropdown from "./component/StateMunicipalityDropdown";

const Home = () => {
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [plateNo, setPlateNo] = useState("");
  const [onboardingId, setOnboardingId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [rawPhoneNumber, setRawPhoneNumber] = useState("");
  const imageUrl = process.env.PUBLIC_URL + "/images/Car-Banner-01.jpg";

  const handleFieldBlur = (field, value) => {
    // Generate onboardingId if it doesn't exist
    if (!onboardingId && onboardingId <= 0) {
      fetch(API_URL + "/onboarding/generate/initial")
        .then((response) => response.json())
        .then((data) => {
          setOnboardingId(data.onboardingId);
          updateOnboardingField(data.onboardingId, field, value);
        })
        .catch((error) => console.error("Error:", error));
    } else {
      // onboardingId already exists, update the field directly
      updateOnboardingField(onboardingId, field, value);
    }
  };

  // Function to update onboarding field
  const updateOnboardingField = (onboardingId, field, value) => {
    fetch(API_URL + "/onboarding/update/initial", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        onboarding_id: onboardingId,
        plate_number: plateNo,
        email,
        phone: rawPhoneNumber,
        municipality_id: selectedState.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle response
      })
      .catch((error) => console.error("Error:", error));
  };

  useEffect(() => {
    fetch(API_URL + "/municipalities")
      .then((response) => response.json())
      .then((data) => {
        setStateOptions(data);
      })
      .catch((error) => {
        console.error("Error fetching municipalities:", error);
      });
  }, []);

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = e.target.value;
    setPhone(formattedPhoneNumber); // Set the formatted phone number in your state
    const rawPhoneNumber = formattedPhoneNumber.replace(/\D/g, ""); // Remove non-numeric characters
    setRawPhoneNumber(rawPhoneNumber); // Set the raw phone number in your state
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!selectedState) {
      validationErrors.state = "Please select a county.";
    }
    if (!plateNo) {
      validationErrors.plateNo = "Please enter a plate number.";
    }
    if (!email) {
      validationErrors.email = "Please enter an email.";
    } else if (!validateEmail(email)) {
      validationErrors.email = "Please enter a valid email.";
    }
    if (!phone) {
      validationErrors.phone = "Please enter a phone number.";
    } else if (!validatePhone(phone)) {
      validationErrors.phone = "Please enter a valid phone number.";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);

      // Combine all error messages into one with HTML line breaks
      const combinedErrorMessage = Object.values(validationErrors).join("<br>");

      // Display error messages using SweetAlert2 with HTML content
      Swal.fire({
        icon: "error",
        title: "Please fix the following issues:",
        html: `${combinedErrorMessage}`,
        showCloseButton: true,
      });

      return;
    } else {
      fetch(API_URL + "/onboarding", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          onboarding_id: onboardingId,
          plate_number: plateNo,
          email,
          phone: rawPhoneNumber,
          municipality_id: selectedState.value,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.errors) {
            setErrors(data.errors);
            const errorMessages = Object.values(data.errors).join("\n");

            // Open login popup and prefill email on email-related error
            if (data.errors.email) {
              const loginButton = document.getElementById("loginButtonTop");
              if (loginButton) {
                loginButton.click(); // Trigger the click event
                //document.getElementById('loginEmail').value= email;
              }
              Swal.fire({
                icon: "error",
                title: "Email already in use.",
                html: "Please login or use a different email.",
                timer: 2000, // Auto-close after 5 seconds
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Submission failed. Error(s):",
                html: `${errorMessages}`,
                showCloseButton: true,
              });
            }
          } else {
            setSelectedState(null);
            setPlateNo("");
            setEmail("");
            setPhone("");
            setErrors({});
            const encryptedOnboardingId = data.encrypted_onboarding_id;
            navigate(`/signup-wizard/${encryptedOnboardingId}`);
          }
        })
        .catch((error) => {
          console.error("Error submitting the form. Please try again later.", {
            autoClose: 3000,
          });
        });
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/;
    return phoneRegex.test(phone);
  };

  return (
    <div>
      <Header />
      <div
        id="carousel-example-generic"
        className="carousel slide"
        data-ride="carousel"
      >
        <div className="carousel-inner" role="listbox">
          <div className="item active">
            <img src={imageUrl} alt="" />
            <div className="carousel-caption">
              <h1>
                Car Tag <span>Renewal</span> Made Easy...
              </h1>
            </div>
          </div>
        </div>
        <a
          className="left carousel-control"
          href="#carousel-example-generic"
          role="button"
          data-slide="prev"
        >
          <div className="control-icon" aria-hidden="true"></div>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="right carousel-control"
          href="#carousel-example-generic"
          role="button"
          data-slide="next"
        >
          <div className="control-icon" aria-hidden="true"></div>
          <span className="sr-only">Next</span>
        </a>
      </div>
      <div className="renewal-form-wrap">
        <div className="renewal-form-inner">
          <div className="form-wrap form-wo-border">
            <form onSubmit={handleSubmit} className="form-inline row">
              <div className="col-md-4 col-sm-12 col-xs-12 form-group selectbox-wrap">
                <StateMunicipalityDropdown
                  data={stateOptions}
                  selectedMunicipality={selectedState}
                  setSelectedMunicipality={setSelectedState}
                />
              </div>
              <div className="col-md-2 col-sm-4 col-xs-12 form-group plateNo-icon">
                <FontAwesomeIcon icon={faUser} className="icon" />
                <input
                  type="text"
                  id="plateNo"
                  onBlur={() => handleFieldBlur("plate_number", plateNo)}
                  className={`form-control${
                    errors.plateNo ? " is-invalid" : ""
                  }`}
                  value={plateNo}
                  onChange={(e) => setPlateNo((e.target.value) ? e.target.value.replace(/[^0-9a-zA-Z]+/ig, "") : '')}
                  placeholder="Plate No."
                />
              </div>
              <div className="col-md-2 col-sm-4 col-xs-12 form-group email-icon">
                <FontAwesomeIcon icon={faEnvelope} className="icon" />
                <input
                  type="email"
                  id="email"
                  onBlur={() => handleFieldBlur("email", email)}
                  className={`form-control${errors.email ? " is-invalid" : ""}`}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </div>
              <div className="col-md-2 col-sm-4 col-xs-12 form-group phone-icon">
                <FontAwesomeIcon icon={faPhone} className="icon" />

                <InputMask
                  mask="(999) 999-9999"
                  id="phone"
                  name="phone"
                  value={phone}
                  onBlur={() => handleFieldBlur("phone", phone)}
                  onChange={handlePhoneNumberChange}
                  className={`form-control${errors.email ? " is-invalid" : ""}`}
                  placeholder="Phone No."
                  type="tel" // Use "tel" type for phone numbers
                  inputMode="numeric" // Set inputMode to "numeric" for numeric keypad on mobile
                />
              </div>
              <div className="col-md-2 col-sm-12 col-xs-12 reg-entry">
                <button type="submit" className="btn btn-primary">
                  Go!
                </button>
              </div>
            </form>
          </div>
          <div className="usp-wrap">
            <div className="margin-auto">
              <div className="row">
                <div className="col-md-12">
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faBolt} /> Simplify Tag Renewal
                      <br /> with our user-friendly app
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faBolt} /> Never Miss a Deadline
                      <br /> with timely reminders
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faBolt} /> Secure and Private
                      <br /> data protection
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-30 pb-30 mkt-wrap">
        <div className="row pt-40 pb-40">
          <div className="col-md-12 col-sm-12 col-xs-12 mb-40">
            <h2>
              <span>Our</span> Offerings
            </h2>
          </div>

          <div className="col-md-4 col-sm-12 col-xs-12">
            <div className="description-box">
              <div className="icon des-icon1">
                {/* You can place an icon here */}
              </div>
              <h3>Tag Renewal Made Easy</h3>
              <p>
                Streamline your tag renewal process with our user-friendly
                service. Never miss a renewal deadline.
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-xs-12">
            <div className="description-box">
              <div className="icon des-icon2">
                {/* You can place a different icon here */}
              </div>
              <h3>Automatic Renewals!</h3>
              <p>
                Never Miss a Tag Renewal Again! Stay on the Road Hassle-Free
                with Renew My Tags
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12 col-xs-12">
            <div className="description-box">
              <div className="icon des-icon3">
                {/* You can place another icon here */}
              </div>
              <h3>Dedicated Customer Support</h3>
              <p>
                Our dedicated support team is available to assist you. Contact
                us anytime for expert assistance. Reach out now!
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
